exports.onClientEntry = () => {

        const httpRequest = new XMLHttpRequest();
        httpRequest.open('HEAD', window.location.href, false);
        httpRequest.send();
        if (httpRequest.status === 404) {
            let data = {
                'url': window.location.href,
                'agent': navigator.userAgent,
                'domain': window.location.hostname,
            };
            fetch("https://cms.cyrekdigital.com/wp-json/cyrekapi/v1/register-404", {
                method: "POST",
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            }).then(res => {

            });
        }

    // if (userLang === "pl" && !window.location.pathname.startsWith("/pl")) {
    //     window.location.pathname = `/pl${window.location.pathname}`
    // }
}
if (process.env.NODE_ENV === "production") {

    let maxScrollPercent = 0;
    let currentTime = 0;
    let eventCalled = false;
    exports.onInitialClientRender = () => {
        function getScrollPercent() {
            const h = document.documentElement;
            const b = document.body;
            return ((h.scrollTop || b.scrollTop) / ((h.scrollHeight || b.scrollHeight) - h.clientHeight)) * 100;
        }

        window.addEventListener('scroll', () => {
            maxScrollPercent = Math.max(maxScrollPercent, getScrollPercent());
        });
        setInterval(() => {
            currentTime++;
            if (currentTime >= 120 && maxScrollPercent >= 75 && !eventCalled) {
                eventCalled = true;
                dataLayer.push(
                    {
                        'event': 'Engagement'
                    });
            }
        }, 1000);
    };
    exports.onRouteUpdate = () => {
        maxScrollPercent = 0;
        currentTime = 0;
        eventCalled = false;
    };
}